import React,{ useState } from "react";
import './gallery.css'
import Dropdown from "./drop-down";
import Filterbutton from "./filter-button";

const Gallerybuttons = ({onYearChange}) =>{
  const [isVisible, setIsvisible] = useState(false)

  const menuVisibilty = () =>{
    setIsvisible(!isVisible)
  }
    //

    return(
        <div className="galleryheader">
          <div className="buttons">
            <button onClick={() => onYearChange('2024')}>2024</button>
            <button onClick={() => onYearChange('2023')}>2023</button>
            <button onClick={() => onYearChange('2022')}>2022</button>
            <button onClick={() => onYearChange('2021')}>2021</button>
          </div>
          < div className="filter-button-div">
            <Filterbutton onClick={menuVisibilty}/>
            <Dropdown isVisible={isVisible}/>
          </div>
          
        </div>
    );
};

export default Gallerybuttons