import React from "react";
import brandsData from '../json-files/vendors-2024-DEC.json';
import './vendors.css';

const VendorcardDec = ({ searchQuery = "", openModal, setModalData }) => {
    const query = typeof searchQuery === "string" ? searchQuery.toLowerCase() : "";
    const brands = brandsData.brands
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .filter((brand) => brand.name.toLowerCase().includes(query));

    return (
        <>
            {brands.map((brand, index) => {
                const logourl = `/imgs/logos/${brand.logo}`;
                return (
                    <div
                        className="vendor-square"
                        key={index}
                        onClick={() => {
                            setModalData(brand); // Set vendor data for the modal
                            openModal(); // Open the modal
                        }}
                    >
                        <div className="vendor-inner-square">
                            <img src={logourl} alt={`${brand.name} logo`} />
                            <h3>{brand.name}</h3>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default VendorcardDec;
