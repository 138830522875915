import React from "react";
import GalleryPanes from "../components/gallery-panes";

const Gallery =() =>{
    return(
        <div className="Outer-gallery">
            <GalleryPanes/>
        </div>

    )
}

export default Gallery