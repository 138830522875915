import React from "react";

const Error = () =>{
    return(
        <>
            <h1>
                ERROR 404,
                THE PAGE YOURE LOOKING FOR DOESNT EXIST

            </h1>
        </>
    );
};

export default Error