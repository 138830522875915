import React from 'react';
import Slideshow from '../components/slideshow';
import Homeabout from '../components/home-about';
import Offroadhome from '../components/offroad-home';
import './home.css'
import Collabo from '../components/collabs';

const images = [
    '/slideshow/slide1.jpg',
    '/slideshow/slide2.jpg',
    '/slideshow/slide3.jpg',
    '/slideshow/slide4.jpg',
    '/slideshow/slide5.jpg'
]

const Homepage = () =>{
    return(
        <>
            <div className='Hero'>
                <Slideshow images={images} interval={2000} /> 
            </div>
            <Homeabout/>
            <Offroadhome/>
            <Collabo/>
        </>
    );
};

export default Homepage