import React from "react";
import './search.css'

const SearchBar = ({ searchQuery, setSearchQuery })=>{
    return(
        <input id="search" className="inputbar" placeholder="search for brand" value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}/>
    )
}

export default SearchBar