import React from "react";
import './gallery.css'
function Dropdown(props){
    return (
        <>
            {props.isVisible ? (
                <ul  className='list-buttons' style={{ position: "absolute", display: "flex", flexDirection: "column", padding:"0" + "px",margin:"0px"}}>
                    <button>APRIL</button>
                    <button>JULY</button>
                    <button>DECEMBER</button>
                </ul>
      ) : null}           
        </>
    )
}

export default Dropdown;