import React from "react";
import { Link } from "react-router-dom";
import './home-about.css'
const Homeabout = () => {
    return(
        <div className="out">
            <h3>EVENTS</h3>
            <div className="festival-section" style={{borderBottom: '6' + 'px' }}>
            <div className="festival-write-up">
                <h2>FESTIVALS</h2>
                <h4>
                Bizarre Bazaar Festivals celebrate the best of African handmade, homemade and artisanal. Whether small scale or mass-produced, we work with creatives from all over Africa and the world, to showcase great products and services in a festive open-air environment. Bizarre Bazaar festivals are FUN. FAMILY. FRIENDLY. events. <br></br>
                <button className="festival-btn"><Link to ='/events'>CLICK HERE</Link> </button>
                </h4>
            </div>
            <div className="vector-lady" style={{
                background: `linear-gradient(rgba(0, 0, 0, 0.641), rgba(0, 0, 0, 0.393)), url('/images/misc-stills-april-2024/DSC_0743.webp') center / cover`,
                }}>
            </div>
        </div>
        </div>
    )
}
export default Homeabout