import React, { useState } from "react";
import './gallery.css';
import Lightbox from './lightbox'; // Import the new Lightbox component
import './lazyimage'
import Gallerybuttons from "./gallerybuttons";
const imageData = {
    '2024':[
        '/images/july-2024/JPEG/DSC_1128.webp',
        '/images/july-2024/JPEG/DSC_1129.webp',
        '/images/misc-stills-april-2024/DSC_9294.webp',
        '/images/misc-stills-april-2024/DSC_0713.webp',
        '/images/misc-stills-april-2024/DSC_0122.webp',
        '/images/misc-stills-april-2024/DSC_0076.webp',
        '/images/misc-stills-april-2024/DSC_0149.webp',
        '/images/july-2024/JPEG/DSC_1131.webp',
        '/images/july-2024/JPEG/DSC_1134.webp',
        '/images/misc-stills-april-2024/DSC_0103.webp',
        '/images/misc-stills-april-2024/DSC_0005.webp',
        '/images/misc-stills-april-2024/DSC_0101.webp',
        '/images/misc-stills-april-2024/DSC_0103.webp',
        '/images/misc-stills-april-2024/DSC_0746.webp',
        '/images/july-2024/JPEG/DSC_1135.webp',
        '/images/july-2024/JPEG/DSC_1136.webp',
        '/images/july-2024/JPEG/DSC_1137.webp',
        '/images/july-2024/JPEG/DSC_1547.webp',
        '/images/misc-stills-april-2024/DSC_0003.webp',
        '/images/misc-stills-april-2024/DSC_0062.webp',
        '/images/misc-stills-april-2024/DSC_0041.webp',
        '/images/misc-stills-april-2024/DSC_0054.webp',
        '/images/misc-stills-april-2024/DSC_9303.webp',
        '/images/misc-stills-april-2024/DSC_0744.webp',
        '/images/july-2024/JPEG/DSC_1605.webp',
        '/images/july-2024/JPEG/DSC_1747.webp',
        '/images/july-2024/JPEG/DSC_1808.webp',
        '/images/july-2024/JPEG/DSC_1809.webp',
        '/images/misc-stills-april-2024/DSC_0102.webp',
        '/images/misc-stills-april-2024/DSC_9222.webp',
        '/images/misc-stills-april-2024/DSC_9187.webp',
        '/images/misc-stills-april-2024/DSC_9208.webp',
        '/images/misc-stills-april-2024/DSC_9301.webp',
        '/images/misc-stills-april-2024/DSC_9303.webp',
        '/images/misc-stills-april-2024/DSC_9192.webp',
        '/images/misc-stills-april-2024/DSC_0740.webp',
        '/images/misc-stills-april-2024/DSC_0733.webp',
        '/images/misc-stills-april-2024/DSC_0001.webp',
        '/images/misc-stills-april-2024/DSC_0002.webp',
        '/images/misc-stills-april-2024/DSC_9182.webp',
        '/images/misc-stills-april-2024/DSC_9206.webp',
   ],
    '2023':[
        
        
    ],
    '2022':[
        
    ]
    
};

const GalleryPanes = () => {
    const [clickable, setClickable] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [selectedYear, setSelectedYear] = useState('2024')

    
    const getImagePaths = () => {
        return imageData[selectedYear]|| [];
      };
    
    const imagePaths = getImagePaths();  

    const handleImageClick = (item, index) => {
        setCurrentIndex(index);
        setClickable(item);
    };

    const closeLightbox = () => {
        setClickable(null); // Close the lightbox
    };

    const showNextImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === imagePaths.length - 1 ? 0 : prevIndex + 1
        );
    };

    const showPreviousImage = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? imagePaths.length - 1 : prevIndex - 1
        );
    };

    const imageHolderSquare = (src, index) => (
        <div key={index} className="imageholdersquare">
            <img
                src={src}
                loading="lazy"
                alt="gallery"
                onClick={() => handleImageClick(src, index)}
            />
        </div>
    );

    const imageHolderPortrait = (src, index) => (
        <div key={index} className="imageholderportrait">
            <img
                src={src}
                alt="gallery"
                loading="lazy"
                onClick={() => handleImageClick(src, index)}
            />
        </div>
    );

    const renderImageHolders = () => {
        return imagePaths.map((path, index) =>
            index % 2 === 0
                ? imageHolderSquare(path, index)
                : imageHolderPortrait(path, index)
        );
    };

    return (
            <div className="outer-gallery" >  
                <Gallerybuttons
                    onYearChange= {setSelectedYear}
                />
                <div className="gallerypanes">
                    {renderImageHolders()}

                    {/* Lightbox Component */}
                    <Lightbox
                        clickable={clickable}
                        currentIndex={currentIndex}
                        closeLightbox={closeLightbox}
                        showNextImage={showNextImage}
                        showPreviousImage={showPreviousImage}
                        imagePaths={imagePaths}
                    />
                </div>
            </div>
)};

export default GalleryPanes;
