import React, { useEffect } from "react";

const AttendeeForm = () => {
    useEffect(() => {
        const loadAweberScript = () => {
            const script = document.createElement('script');
            script.src = "//forms.aweber.com/form/18/726744618.js"; // Your Aweber script source
            script.async = true;
            script.onload = () => {
                console.log('Aweber script loaded for Attendee Form');
            };
            document.body.appendChild(script);
        };

        loadAweberScript();

        // Cleanup function to remove the script on unmount
        return () => {
            const script = document.querySelector('script[src="//forms.aweber.com/form/18/726744618.js"]');
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);

    return <div className="AW-Form-726744618"></div>; // Ensure this matches the Aweber selector
};

export default AttendeeForm;
