import React from "react";

const Offroadhome = () =>{
    return(
        <div className="festival-section">
            <div style={{background: '#532200', marginTop: '6' +'px'}} className="festival-write-up">
                <h2>OFFROAD</h2>
                <h4>
                The Offroad 4x4 Event celebrates rugged adventure and automotive craftsmanship with powerful vehicles and expert driving skills. It features adrenaline-pumping obstacle courses and family-friendly activities in an open-air setting. The event promotes adventure, community, and exploration, offering excitement for off-road fans and families.
                </h4>
            </div>
            <div className="vector-lady" style={{
                background: `linear-gradient(rgba(0, 0, 0, 0.641), rgba(0, 0, 0, 0.393)), url('/images/offroad-4x4.webp') center / cover`,
                marginTop: '6px' // Adjust as needed
                }}>
            </div>
        </div>
    )
}
export default Offroadhome