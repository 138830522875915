import React from 'react';
import './Navbar.css'; // Import the CSS file

const Navbar = () => {
  return (
    <nav className="navbar">
      <div>
        <div className="nav-item"><a className="nav-link" href="home"><img src='/logo.jpg' alt='logo'/></a></div>
      </div>
      <div className='nav-items'>
            <li className='nav-item'><a className='nav-link' href='home'>Home</a></li>
            <li className="nav-item"><a className="nav-link" href="about">About</a></li>
            <li className="nav-item"><a className="nav-link" href="contact">Contact</a></li>
            <li className="nav-item"><a className="nav-link" href="gallery">Gallery</a></li>
      </div>
    </nav>
  );
};

export default Navbar;