import React from "react";
import Collaborators from "./collaborators";
import './collaborators.css'


const Collabo = () =>{
    return(
        <div className="pty">
            <h3>PARTNERSHIPS</h3>
            <div className="partnerships">
                <div>
                    <Collaborators
                    collabImg="/images/If We Build It (large_logo_transparent).webp"
                    collabwriteup="Biz Baz Events is not only an event partner with the Nairobi Baseball Community under the brand If We Build It - we will be hosting some of our vendors to give you a taste of the Bizarre Bazaar Festival Experience.All products are Proudly Made in Kenya "
                    collabDates="September 15th 2024"
                    />
                </div>
                <div>
                    <Collaborators
                    collabImg="/images/If We Build It (large_logo_transparent).webp"
                    collabwriteup="Biz Baz Events is not only an event partner with the Nairobi Baseball Community under the brand If We Build It - we will be hosting some of our vendors to give you a taste of the Bizarre Bazaar Festival Experience.All products are Proudly Made in Kenya "
                    collabDates="September 15th 2024"
                    />
                </div>
                <div>
                    <Collaborators
                    collabImg="/images/If We Build It (large_logo_transparent).webp"
                    collabwriteup="Biz Baz Events is not only an event partner with the Nairobi Baseball Community under the brand If We Build It - we will be hosting some of our vendors to give you a taste of the Bizarre Bazaar Festival Experience.All products are Proudly Made in Kenya "
                    collabDates="September 15th 2024"
                    />
                </div>
                <div>
                    <Collaborators
                    collabImg="/images/If We Build It (large_logo_transparent).webp"
                    collabwriteup="Biz Baz Events is not only an event partner with the Nairobi Baseball Community under the brand If We Build It - we will be hosting some of our vendors to give you a taste of the Bizarre Bazaar Festival Experience.All products are Proudly Made in Kenya "
                    collabDates="September 15th 2024"
                    />
                </div>
            </div>
        </div>
    )
}


export default Collabo