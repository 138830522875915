import React, { useEffect } from 'react';

const VendorForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//forms.aweber.com/form/37/256996137.js";
        script.async = true;
        script.onload = () => {
            console.log('Aweber script loaded');
        };
        document.body.appendChild(script);

        // Cleanup function to remove the script when unmounting
        return () => {
            const existingScript = document.querySelector('script[src="//forms.aweber.com/form/37/256996137.js"]');
            if (existingScript) {
                document.body.removeChild(existingScript);
            }
        };
    }, []); // Runs on mount and unmount

    return <div className="AW-Form-256996137"></div>; // Class for the Aweber form
};

export default VendorForm;


