import React, { useState } from "react";
import './contact.css';
import logo from '../images/logo/logo.jpg';
import VendorForm from "../components/vendor-form";
import CustomerForm from "../components/customer-form";

const Contact = () => {
    const [activeForm, setActiveForm] = useState('vendor');

    return (
        <div className="contact">
            <div className="form-img">
                <img src={logo} alt="logo" />

                <div className="form-switcher">
                    <button 
                        onClick={() => setActiveForm('attendee')} 
                        className={activeForm === 'attendee' ? 'active' : ''}
                    >
                        Attendee
                    </button>
                    <button 
                        onClick={() => setActiveForm('vendor')} 
                        className={activeForm === 'vendor' ? 'active' : ''}
                    >
                        Vendor
                    </button>
                </div>
            </div>
            <div className="Form">
                {/* Keep both forms rendered and use CSS to hide/show */}
                <div style={{ display: activeForm === 'vendor' ? 'block' : 'none', width:'100%' , overflow: 'scroll' }}>
                    <VendorForm />
                </div>
                <div style={{ display: activeForm === 'attendee' ? 'block' : 'none' }}>
                    <CustomerForm />
                </div>
            </div>
        </div>
    );
};

export default Contact;


