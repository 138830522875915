import React from "react";
import './footer.css'


const GlobalFooter = () =>{
    return(
        <>
            <div className="footer">
                <div className="socials">
                    <h2>Socials:</h2>
                    <a
                    class='socials'
                    href="https://instagram.com/bizbazevents/"
                    target='_blank'
                    rel="noopener"
                    aria-label='Github'
                    >
                        <h4>Instagram</h4>
                    </a>
                    <a 
                    class='socials'
                    href="https://facebook.com/@bizbazevents/"
                    target='_blank'
                    rel="noopener"
                    aria-label='Github'
                    ><h4>Facebook</h4></a>
                    <a
                    class= 'socials'
                    href="https://x.com/bizbazevents/"><h4>Twitter</h4></a>
                </div>
                <div className="reach">
                    <h2>Reach us:</h2>
                    <h4>
                        call - 0701 395 902
                    </h4>
                    <h4>
                        Email - info@bizbazevents.com
                    </h4>
                </div>
                <div className="Location">
                    <h2>Offices</h2>
                    <h4>Nairobi Garage,<br/> Karen</h4>
                </div>
            </div>
        </>
    )
}

export default GlobalFooter