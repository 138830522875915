import React from 'react';

function Filterbutton(props){
    return(
        <div style={{display:"flex", flexDirection: "column"}}>
            <button onClick={props.onClick}>filter</button>
        </div>
    )
}

export default Filterbutton