import React from "react";
import './eventpage.css'
import VendorcardDec from "../components/vendor-card-2024-dec";
import { useState } from "react";


const Eventpage = () =>{

    const [searchQuery, setSearchQuery] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return(
        <>
            <div className="outer-body-event">
                <div className="column1">
                        <img src="/images/banner-24.webp" alt="whatever" />
                </div>
                <div className="column-1">
                    <div className="writeup-body">
                        <h2>Christmas Festival 2024</h2>
                        <h4>Bizarre Bazaar Festival Christmas Edition on Friday 13th, saturday 14th & Sunday 15th April Bizarre Bazaar Festival - the largest open-air market in Kenya packed with entertaining AND fun things to do and enjoy. Diverse brands from all over Africa and price points just for you. We've got you covered. Bizarre Bazaar Fun, Family, Friendly event.</h4>
                    </div>
                    <div className="writeup-body">
                        <h2>TICKETS</h2>
                        <div className="ticketbox">
                            <h4>coming soon</h4>
                        </div>
                    </div>
                    <div className="writeup-body">
                        <img src="/images/april-map-copy.webp" alt="whatever"/>
                    </div> 
                                     
                </div>
                <div className="row2">
                    <h2>Activities</h2>
                    <div className="activities-grid">
                        <div className="grid">
                            <img src="/images/svg-vectors/stall-svgrepo-com.svg" alt="food"/>
                            <li>100+ vendors </li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/food-dinner-svgrepo-com.svg" alt="food"/>
                            <li>food & drink</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/dog-svgrepo-com.svg" alt="dog-friendly"/>
                            <li>Dog friendly</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/drummer-set-svgrepo-com.svg" alt="food"/>
                            <li>Live performances<br/>& DJ</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/extreme-sport-acrobats-couple-svgrepo-com (1).svg" alt="food"/>
                            <li>Sarakasi trust Acrobats</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/playground-svgrepo-com.svg" alt="food"/>
                            <li>Kids playground</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/baseball-svgrepo-com.svg" alt="food"/>
                            <li>Baseball & softball station</li>
                        </div>                        
                    </div>
                </div>
                <div>
                    <diV className='vendors-container'>
                        <VendorcardDec
                            searchQuery={searchQuery}
                            openModal={openModal}
                            setModalData={setModalData}
                        />
                    </diV>
                    {isModalOpen && modalData && (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            
                            <button className="close-button" onClick={closeModal}>X</button>
                            <img src={modalData.logo}></img>
                            <h2>{modalData.name}</h2>
                            <p>{modalData.description}</p>
                        </div>
                    </div>
                )}
                </div>
            </div>
        </>
    )
}

export default Eventpage