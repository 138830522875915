import React from "react";
import Posters from "../components/x-mas-2024-poster";
import './events.css'
const Events = () =>{
    return(
        < div className="eventspages" style={{justifyContent: 'center', display: "flex", flexDirection: "column"}}>
            <Posters
                imagepath='/images/posters/2024/christmas-2024.webp'
                headertext="BIZARRE BAZAAR X-MAS FESTIVAL 2024"
                venue=" KSTVET,UN AVENUE RD, OPP WARWICK CENTER."
                date=" FRI 13TH, SAT 14TH, SUN 15TH, DEC 2024."
                vendors="Currently not accepting vendor applications"
                vendorslink=""
                button='/eventpage'
            />
            <Posters
                imagepath='/images/posters/2024/july-2024.webp'
                headertext="BIZARRE BAZAAR JULY FESTIVAL 2024  "
                venue=" KSTVET,UN AVENUE RD, OPP WARWICK CENTER"
                date=" SAT 27TH, SUN 28TH, JULY 2024"
                button="/events"
                vendors="July 2024 festival vendors "
                vendorslink="/vendors"
            />
            <Posters
                imagepath='images/posters/2024/april-poster-2024.webp'
                headertext="BIZARRE BAZAAR APRIL FESTIVAL 2024 "
                venue=" KSTVET,UN AVENUE RD, OPP WARWICK CENTER." 
                date=" SAT 6TH, SUN 7TH, APRIL 2024."  
                vendors="April 2024 festival vendors" 
                button="/eventpage"
            />
        </div>
    );
};
export default Events   