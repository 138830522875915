import React from "react";
import PropTypes from 'prop-types'
import './collaborators.css'


const Collaborators = ({collabImg, collabDates, collabwriteup}) =>{
    return(
        <div className="collaborators-outer">
            <div className="collab-card-holder">
                <img src={collabImg} alt="collabs"/>
                <div><p>{collabwriteup}</p></div>
                <div><h5>{collabDates}</h5></div>
            </div>

        </div>
    )
}

Collaborators.propTypes = {
    collabImg: PropTypes.string.isRequired,
    collabDates: PropTypes.string.isRequired,
    collabwriteup:PropTypes.string.isRequired
}
export default Collaborators