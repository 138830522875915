
import './App.css';
import Navbar from './components/navbar';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Homepage from './pages/home';
import Events from './pages/events';
import About from './pages/about';
import Contact from './pages/contact';
import Error from './pages/error';
import Gallery from './pages/gallery';
import Vendors from './pages/vendors';
import Eventpage from './pages/event-page';
import ScrollToTop from './components/Scroll-to-top';
import VendorsDec from './pages/vendors-december';
import GlobalFooter from './components/footer';


function App() {
  return (
    <Router>
      <ScrollToTop/>
      <div>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Homepage/>} />
          <Route path='home' element={<Homepage/>}/>
          <Route path='events' element={<Events/>}/>
          <Route path='contact' element={<Contact/>}/>
          <Route path='about' element={<About/>}/>
          <Route path='*' element={<Error/>}/>
          <Route path='vendors' element={<Vendors/>}/>
          <Route path='gallery' element={<Gallery/>}/>
          <Route path= 'eventpage' element={<Eventpage/>}/>
          <Route path='Vendors-dec' element={<VendorsDec/>}/>
        </Routes>
        <GlobalFooter/>
      </div>
    </Router>
    
  );
}

export default App;
