import React, { useState, useEffect } from 'react';
import './slideshow.css'; // Add your custom styles here
import { Link } from 'react-router-dom';

const Slideshow = ({ images, interval = 2000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically change slides
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);

    // Cleanup interval on unmount
    return () => clearInterval(timer);
  }, [images.length, interval]);


  return (
    <div
      className="hero-section"
      style={{ backgroundImage: `url(${images[currentIndex]})` }}
    >
      {/* Dark Overlay */}
      <div className="hero-overlay"></div>

      {/* Hero Content */}
      <div className="hero-content">
        <h3>Where Creativity Meets Experience</h3>
        <button className="hero-button">
            <Link to ='/About'>see more ...</Link>
        </button>
      </div>
    </div>
  );
};

export default Slideshow;