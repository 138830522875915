import React from "react";
import './lightbox.css';

const Lightbox = ({ clickable, currentIndex, closeLightbox, showNextImage, showPreviousImage, imagePaths }) => {
    if (!clickable) return null; // Don't render if no image is selected

    return (
        <div className="lightbox">
            <span className="close" onClick={closeLightbox}>
                &times;
            </span>
            <button className="prev" onClick={showPreviousImage}>
                &#10094;
            </button>
            <img
                className="lightbox-content"
                src={imagePaths[currentIndex]}
                alt="gallery"
            />
            <button className="next" onClick={showNextImage}>
                &#10095;
            </button>
        </div>
    );
};

export default Lightbox;
