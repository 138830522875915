import React from "react";
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import './poster.css'

const Posters = ({imagepath,headertext, venue, date,button, vendors, vendorslink}) => {
    return(
        <div className="poster-box">
            <div className="poster-inner">
                <img src={imagepath} alt="poster" className="poster-image" />
            </div>
            <div className="poster-body">
                <h2>{headertext}</h2>
                <div className="middletext">
                    <h4>VENUE: {venue}</h4>
                    <h4>DATE: {date}</h4>
                    <h4>VENDORS: {vendors} <a href={vendorslink}>, click here</a>  </h4>
                    <button> <Link to = {button} >see more</Link></button>
                </div>
            </div>
        </div>
    )
}
Posters.propTypes = {
    imagepath: PropTypes.string.isRequired,
    headertext: PropTypes.string.isRequired,
    venue: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    vendors:PropTypes.string.isRequired,
    vendorslink:PropTypes.string.isRequired
}
export default Posters